// @ts-nocheck
import { get, post, ffsmBaseURL } from './request'

export default {
  register(params) {
    return post('user/register', params, { tip: true })
  },
  modifyPassword(params) {
    return post('user/modifyPassword', params, { tip: true })
  },
  login(params) {
    return post('user/login', params, { tip: true })
  },
  updateFreetimes(params) {
    return post('user/updateFreetimes', params)
  },
  userInfo(params) {
    return post('user/userInfo', params)
  },
  orderList(params) {
    return post('api/orderList', params, { baseUrl: ffsmBaseURL })
  },
  pay(params) {
    return post('user/pay', params)
  },
  queryOrder(params) {
    return post('user/queryOrder', params)
  },
  getCount(params) {
    return post('user/getCount', params)
  },
  captcha(params) {
    return get('user/captcha', params)
  },
  sendMsg(params) {
    return post('user/sendMsg', params)
  },
}
