import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '@/pages/home/index.vue'
import Product from '@/pages/product/index.vue'
import Product1 from '@/pages/product/index1.vue'
import Personal from '@/pages/personal/index.vue'
import About from '@/pages/about/index.vue'
import PaySuccess from '@/pages/pay/success.vue'
// import { writeMeta } from '@/util/writeMeta'

const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      component: () => import('@/pages/home/index.vue'),
    },
    {
      path: '/personal',
      component: () => import('@/pages/personal/index.vue'),
    },
    {
      path: '/about',
      component: () => import('@/pages/about/index.vue'),
    },
    {
      path: '/paysuccess/:username/:appKey/:times/:give',
      component: () => import('@/pages/pay/success.vue'),
    },
    {
      path: '/product/:id',
      component: () => import('@/pages/product/index.vue'),
    },
    {
      path: '/product/xmbzxp',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/sndyxp',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/bzjp',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/bzhh',
      component: () => import('@/pages/product/index1.vue'),
    },

    {
      path: '/product/bzyy',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/yycs',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/xmpd',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/zwmp',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/xzys',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/gsqm',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/sjhjx',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/cphjx',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tlys',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/bbqm',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/jinnian',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/xmfx',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tlfs',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tlal',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tlfh',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tlzh',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tlaq',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tlxl',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tljx',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tltd',
      component: () => import('@/pages/product/index1.vue'),
    },
    {
      path: '/product/tlbr',
      component: () => import('@/pages/product/index1.vue'),
    },
  ],
})

router.beforeEach((to: any, form, next) => {
  if (to.path == '/product') {
    router.push(`/product/sndyxp`)
  }

  try {
    // 用于发送某个指定URL的PV统计请求
    ;(window as any)._hmt.push(['_trackPageview', to.fullPath])
    // console.log(window._hmt, 'window._hmt')
  } catch (e) {
    console.log('百度统计', e)
  }
  // writeMeta(to)
  next()
})

//router/index.js

export default router
