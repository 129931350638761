// @ts-nocheck

import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-persistedstate-plugin'
// 创建store实例
const store = createPinia()
store.use(
  createPersistedState({
    storage: window.localStorage,
  })
)
export function setupStore(app: App<Element>) {
  app.use(store)
}
export default store
