import axios from 'axios'
import qs from 'qs'
import { ElMessage, ElLoading } from 'element-plus'

// export const { VITE_FFSM_BASE_URL: ffsmBaseURL, VITE_BASE_URL: baseURL } = import.meta.env
export let { VUE_APP_FFSM_BASE_URL: ffsmBaseURL, VUE_APP_BASE_URL: baseURL } = process.env
// baseURL = 'https://apidoc.sanqizhouyi.com:81/'

const service = axios.create({
  baseURL,
  // withCredentials: true,
  headers: {
    // 'content-type': 'application/x-www-form-urlencoded',
    'Content-Type': 'application/json',
  },
  timeout: 20000,
})

// api 请求拦截器
service.interceptors.request.use(
  config => {
    const isBase: any = qs.parse(config.data).isBase
    if (isBase === false) {
      service.defaults.baseURL = ''
    } else {
      service.defaults.baseURL = baseURL
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// api 响应拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  err => {
    console.log(err, 'errr')
    ElMessage.error('请求出错,请稍候再试')
    return Promise.reject(err)
  }
)

// 发送POST请求
export const post = (url: string, params = {}, other: any = {}) => {
  if (other.baseUrl) {
    service.defaults.baseURL = other.baseUrl
  } else {
    service.defaults.baseURL = baseURL
  }
  // params = qs.stringify(params)
  return service
    .post(url, params)
    .then((res: any) => {
      // loading.close()
      if (other.msg) {
        ElMessage.error(other.msg)
      }
      if (res.code == 1000) {
        if (other.tip) {
          res.msg && ElMessage.success(res.msg)
        }
        return Promise.resolve(res.data)
      } else {
        if (res.msg) {
          ElMessage.error(res.msg)
        }
        return Promise.reject(res)
      }
    })
    .catch(err => {
      console.log(err)
      return Promise.reject(err)
    })
}

// 发送GET请求
export const get = (url: string, params: any) => {
  return service
    .get(url, {
      params: params,
      headers: {},
    })
    .then((res: any) => {
      if (res.code == 1000) {
        return Promise.resolve(res.data)
      } else {
        res.msg && ElMessage.error(res.msg)
        return Promise.reject(res)
      }
    })
}

export default service
