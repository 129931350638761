import { defineStore } from 'pinia'
import api from '@/api/index'

export const apiStore = defineStore({
  id: 'api', // 命名，唯一
  state: () => {
    return {
      userInfo: {},
      navActive: '/',
      msgSecond: 119, // 获取短信验证码剩余时间
    }
  },
  actions: {
    setNavActive(data: any) {
      this.navActive = data
    },
    async setUserInfo(data: any) {
      const res = await api.login(data)
      this.userInfo = res
    },
    async getUserInfo(data: any) {
      const res = await api.userInfo(data)
      if (res) this.userInfo = res
    },
  },
})
