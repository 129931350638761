// @ts-nocheck
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'highlight.js/styles/default.css'
import hljs from 'highlight.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/assets/font/iconfont.css'
import { createMetaManager } from 'vue-meta'

import VueDOMPurifyHTML from 'vue-dompurify-html'

const app = createApp(App)

app.use(VueDOMPurifyHTML)

app.use(store)
app.use(ElementPlus)
app.use(router)
app.use(
  createMetaManager(false, {
    meta: { tag: 'meta', nameless: true },
  })
)

app.config.globalProperties.imageUrl = (name: string) => {
  return new URL(`./assets/images/${name}`, import.meta.url).href
}
// element图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 代码亮显示指令
app.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach((block: any) => {
    hljs.highlightBlock(block)
  })
})
app.config.globalProperties.$hljs = hljs

app.config.globalProperties.$SeoUpdate = (SeoTitle, SeoKeywords, SeoDescription) => {
  let _headDom = '',
    _title = '',
    _meta = ''
  //获取head节点
  _headDom = document.getElementsByTagName('head')[0]
  //获取head节点下的title节点
  _title = _headDom.getElementsByTagName('title')[0]
  //获取head节点下的meta节点，它一般是一个数组
  _meta = _headDom.getElementsByTagName('meta')
  _title.innerText = SeoTitle
  for (let index = 0; index < _meta.length; index++) {
    switch (_meta[index].name) {
      case 'keywords':
        _meta[index].content = SeoKeywords
        break
      case 'description':
        _meta[index].content = SeoDescription
        break
      case 'searchtitle':
        _meta[index].content = SeoDescription
        break

      default:
        break
    }
  }
}

app.mount('#app')
